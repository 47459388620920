<template>
  <div v-b-tooltip.left="'This is an impersonated session'" style="position: relative;">
    <b-dropdown variant="danger" id="dropdown-impersonate" right :text="`Hi! ${currentUser.i.name}`" class="m-2">

      <b-dropdown-item @click="revertToUser">
        <span style="display: flex; align-items: center;">
          Logout
          <span style="margin-left: 10px; display: flex; align-items: center;">
            <code>{{ currentUser.u.name }}</code>
            <i class="menu-icon la la-user-secret" style="color: red; font-weight: bold;"></i>
          </span>
        </span>
      </b-dropdown-item>
      <b-dropdown-item @click="logoutOfAll">Logout <span style="margin-left: 10px;">
        <code>{{ currentUser.i.name }}</code></span>
      </b-dropdown-item>
    </b-dropdown>
    <div style="position: absolute; top: -15px; left: 0;  z-index: 1;">
      <span style="color: orange;font-size: xx-large;">•</span>
    </div>
  </div>
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
// import i18nService from "@/core/services/i18n.service.js";
import { mapGetters } from "vuex";
import { REVERT_TO_USER } from "@/core/services/store/actions.type";
import { LOGOUT } from "@/core/services/store/actions.type";

export default {
  name: "Impersonator",
  components: {
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  methods: {
    revertToUser() {
      // console.log("revertToUser");
      this.$swal
        .fire({
          title: "Are you sure?",
          text:
            "Would you like to Logout from User: " + this.currentUser.u.name + "?",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          showCancelButton: true,
          showCloseButton: true,
          everseButtons: true
        })
        .then(swalStatus => {
          if (swalStatus.isConfirmed) {
            window.localStorage.removeItem("search");
            window.localStorage.removeItem("appoitmentsearch");
            this.$store
              .dispatch(REVERT_TO_USER)
              .then((data) => {
                this.$router.push({
                  name: "admin.dashboard",
                }).then(() => {
                  // we need to refresh the page as old components are being used when admin impersonates the user.
                  // console.log("gone to page and reloaded");
                  window.location.reload();
                });
              });
          }
        });
    },
    logoutOfAll() {
      // console.log("logoutOfAll");
      this.$store.dispatch(LOGOUT).then(() => {
        window.localStorage.removeItem("appoitmentsearch");
        this.$router.push({
          name: "client.login",
          params: { client_slug: this.$route.params.client_slug }
        });
      });
    }
  }
};
</script>
